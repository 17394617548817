import AppStroage from './UserAppStroage'
class User {
    login(data, type = null) {
        let ty = type ? type : "bd";
        AppStroage.store(data, ty);
    }
    loggedIn(type = null) {
        let ty = type ? type : "bd";
        return AppStroage.logged(ty);
    }
    id(type = null) {
        let ty = type ? type : "bd";
        if (this.loggedIn(ty)) {
            if (AppStroage.getUser(ty)) {
                return AppStroage.getUser(ty)['id'];
            } else {
                return "";
            }
        } else {
            return "";
        }
    }
    type(type = null) {
        let ty = type ? type : "bd";
        if (this.loggedIn(ty)) {
            if (AppStroage.getUser(ty)) {
                return AppStroage.getUser(ty)['type'];
            } else {
                return "";
            }
        } else {
            return "";
        }
    }
    logout() {
        axios.post("/logout").then(res => {
            AppStroage.clear(this.type());
            window.location = laravel.baseurl + '/member-login'
        }).catch(error => console.log(error));
    }
    Japanlogout() {
        axios.post("/jplogout").then(res => {
            AppStroage.clear('jp');
            window.location = laravel.baseurl + '/login-panel'
        }).catch(error => console.log(error));
    }
    BDLoggedCheck() {
        axios.get("/check-bdlogin").then((response) => {
            if (!response.data) {
                AppStroage.clear("bd");
            } else {
                let user = {
                    type: "bd",
                };
                this.login(user, "bd");
            }
        });
    }
    JapanLoggedCheck() {
        axios.get("/check-jplogin").then((response) => {
            if (!response.data) {
                AppStroage.clear("jp");
            } else {
                let user = {
                    type: "jp",
                };
                this.login(user, "jp");
            }
        });
    }
}

export default User = new User();