class AppStroage {
    store(data, type) {
        this.storeData(data, type);
    }
    storeData(data, type) {
        localStorage.setItem('loggedUser' + type, true);
        localStorage.setItem('user' + type, JSON.stringify(data));
    }
    clear(type) {
        localStorage.removeItem('loggedUser' + type)
        localStorage.removeItem('user' + type)
    }
    getUser(type) {
        if (localStorage.getItem('user' + type)) {
            try {
                return JSON.parse(localStorage.getItem('user' + type));
            } catch (e) {
                return [];
            }
        }
    }
    logged(type) {
        return localStorage.getItem('loggedUser' + type) ?
            localStorage.getItem('loggedUser' + type) : false
    }
}

export default AppStroage = new AppStroage()