require('./frontend_bootstrap');
window.Vue = require('vue');
require('./Helpers/filter');
require('./Helpers/Frontend/mixin');
require('./Helpers/Frontend/plugin');
require('./Helpers/Frontend/crud');
Vue.mixin(require('./Helpers/Frontend/trans'))

// ===============router=============
import router from "./Router/frontend_router.js";

Vue.component('app', require('./components/FrontendApp.vue').default);
// ===============app===============
const app = new Vue({
    el: '#app',
    router,
    data: {
        baseurl: laravel.baseurl,
        asset: laravel.baseurl + '/public/assets/frontend/',
        noimage: laravel.baseurl + '/public/images/noimage.png',
        spinner: false,
        bdLogged: false,
        jpLogged: false,
        lang: _locale,
        menus: [],
        config: [],
    },
    methods: {
        getMenus: function () {
            axios
                .get("/menus")
                .then(res => {
                    this.menus = res.data.menus;
                    this.config = res.data.config;
                })
                .catch(error => console.log());
        },
    },
    mounted() {
        this.getMenus();
        this.bdLogged = localStorage.getItem('loggedUserbd') ?
            Boolean(localStorage.getItem('loggedUserbd')) : false;
        this.jpLogged = localStorage.getItem('loggedUserjp') ?
            Boolean(localStorage.getItem('loggedUserjp')) : false;
    },
    created() {
        // active parent menu
        // $('.nav-item').children('.nav-link').removeClass('parent-active')
        // setTimeout(() => {
        //     $('.router-link-exact-active').parents('.dropdown-menu').siblings('#navbarDropdown').addClass('parent-active');
        // }, 3000);
    }
});
